import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/common/Layout"
import { getHeaderTitles } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { InfoboxDetailed } from "~/components/page/Infobox"
import { Ul } from "~/components/common/Typography"
import { Box } from "reflexbox"
import Copyright from "~/components/common/Copyright"
import OutsideLink from "~/components/common/OutsideLink"

const Page = ({ myPage = 7, mySubpage }) => {
  return (
    <Layout
      footer={null}
      header={{ ...getHeaderTitles(myPage, mySubpage), hideProgress: true }}
    >
      <InfoboxDetailed headerText={"RESOURCES"}>
        <Box textAlign={"left"} maxWidth={"mediumContent"} mx={"auto"} mt={6}>
          <Ul
            as={"ol"}
            css={css`
              list-style-type: decimal;
              list-style-position: inside;
            `}
          >
            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.womenssportsfoundation.org/support-us/do-you-know-the-factors-influencing-girls-participation-in-sports/"
                }
              >
                Women’s Sports Foundation
              </OutsideLink>
              , “Factors Influencing Girls’ Participation in Sports,” 2016
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.womenssportsfoundation.org/support-us/do-you-know-the-factors-influencing-girls-participation-in-sports/"
                }
              >
                Women’s Sports Foundation
              </OutsideLink>
              , “Factors Influencing Girls’ Participation in Sports,” 2016
            </li>

            <li>
              Anne Driscoll,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.brandeis.edu/investigate/selectedwork/docs/anne-driscoll-articles-giving-girls-a-sporting-chance.pdf"
                }
              >
                Brandeis University.
              </OutsideLink>{" "}
              “Giving Girls a Sporting Chance.” Boston Globe, 1999.
            </li>

            <li>
              Anne Driscoll,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.brandeis.edu/investigate/selectedwork/docs/anne-driscoll-articles-giving-girls-a-sporting-chance.pdf"
                }
              >
                Brandeis University.
              </OutsideLink>{" "}
              “Girls Are Playing Their Own Kind of Game.” Boston Globe, 2000.
            </li>

            <li>
              Katty Kay and Claire Shipman,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.theatlantic.com/magazine/archive/2014/05/the-confidence-gap/359815/#_blank"
                }
              >
                The Atlantic.
              </OutsideLink>{" "}
              “The Confidence Gap.” The Atlantic Monthly Group, 2014.
            </li>

            <li>
              Anne Driscoll,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.brandeis.edu/investigate/selectedwork/docs/anne-driscoll-articles-giving-girls-a-sporting-chance.pdf"
                }
              >
                Brandeis University.
              </OutsideLink>{" "}
              “Giving Girls a Sporting Chance.” Boston Globe, 1999.
            </li>

            <li>
              Lisa Hinkelman,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"https://rulingourexperiences.com/girls-and-sports"}
              >
                Ruling our eXperiences.
              </OutsideLink>{" "}
              “The Girls’ Index: Girls and Sports Impact Report.” Ruling Our
              eXperiences, Inc., 2018.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://ncwge.org/TitleIX45/Title%20IX%20at%2045-Advancing%20Opportunity%20through%20Equity%20in%20Education.pdf"
                }
              >
                NCWGE
              </OutsideLink>{" "}
              "Title IX at 45: Advancing Opportunity Through Equity in
              Education." National Coalition for Women and Girls in Education,
              2017.
            </li>

            <li>
              Alan S. Goldberg,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"https://www.competitivedge.com/about-dr-g"}
              >
                Competitive Advantage.
              </OutsideLink>{" "}
              “About Dr. G,” Competitive Advantage, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://www.amazon.com/dp/B000JMKSWY/"}
              >
                Brooke de Lench
              </OutsideLink>{" "}
              , Home Team Advantage: The Critical Role of Mothers in Youth
              Sports. HarperCollins e-books, 2009.
            </li>

            <li>
              Leanne Norman,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.researchgate.net/publication/270734399_The_Coaching_Needs_of_High_Performance_Female_Athletes_Within_the_Coach-Athlete_Dyad"
                }
              >
                International Sports Coaching Journal.
              </OutsideLink>{" "}
              “The Coaching Needs of High Performance Female Athletes Within the
              Coach-Athlete Dyad.” Human Kinetics, Inc., 2015.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.researchgate.net/publication/311064561_Why_ChildrenYouth_Drop_Out_of_Sports"
                }
              >
                Tek B. Dangi and Peter A. Witt,
              </OutsideLink>{" "}
              “Why Children/Youth Drop Out of Sports.” Research Gate, 2016.
            </li>

            <li>
              Brooke de Lench,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.momsteam.com/successful-parenting/youth-sports-parenting-basics/parenting-girls/raising-an-athletic-daughter-moms?page=0%2C1"
                }
              >
                Moms Team.
              </OutsideLink>{" "}
              “Raising An Athletic Daughter: Moms Play Key Role.” Momsteam,
              2006.
            </li>

            <li>
              Coach Jim,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://activeforlife.com/coaching-girls-in-sport-research/"
                }
              >
                Active for Life.
              </OutsideLink>{" "}
              “Coaching Girls in Sport: What The Research Says.” Active for
              Life, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://www.adultdevelopmentstudy.org/"}
              >
                Harvard Study of Adult Development
              </OutsideLink>{" "}
              , Harvard University. HSGS, 2015.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/Vital-Connections-Harnessing-Relationship-Impact-ebook/dp/B07BL5J8CV"
                }
              >
                Lou Bergholz
              </OutsideLink>{" "}
              , Vital Connections: Harnessing the Power of Relationship to
              Impact the Lives of Young People. Lioncrest Publishing, 2018.
            </li>

            <li>
              Holly Korbey,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"https://www.edutopia.org/article/power-being-seen"}
              >
                Edutopia.
              </OutsideLink>{" "}
              “The Power of Being Seen.” George Lucas Educational Foundation,
              2017.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/Embracing-Victory-Lessons-Competition-Compassion/dp/0788192663"
                }
              >
                Mariah Burton Nelson
              </OutsideLink>{" "}
              , Embracing Victory: Life Lessons in Competition and Compassion.
              Diane Pub Co, 1998.
            </li>

            <li>
              Kathleen J. DeBoer,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.teamusa.org/-/media/TeamUSA/SportPerformance/coaching/Olympic-Coach-E-Mag/Spring-2006.pdf?la=en&hash=DACDD5BB09C2AF8BF6A5578AC4B51DE964BD1756"
                }
              >
                Olympic Coach.
              </OutsideLink>{" "}
              “Practice Like a Girl, Compete Like a Boy: Training the Total
              Athlete.” United States Olympic Committee, 2006.
            </li>

            <li>
              Susan Birk,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.chicagotribune.com/news/ct-xpm-1991-05-26-9102160946-story.html"
                }
              >
                Chicago Tribune.
              </OutsideLink>{" "}
              “Good Sports.” Chicago Tribune, 1991.
            </li>

            <li>
              Anne Driscoll,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.brandeis.edu/investigate/selectedwork/docs/anne-driscoll-articles-girls-are-playing-their-own-kind-of-game.pdf"
                }
              >
                Brandeis University.
              </OutsideLink>{" "}
              “Girls Are Playing Their Own Kind of Game.” Boston Globe, 2000.
            </li>

            <li>
              Brooke de Lench,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.momsteam.com/successful-parenting/youth-sports-parenting-basics/parenting-girls/sports-dropout-rate-for-girls-six"
                }
              >
                Moms Team.
              </OutsideLink>{" "}
              “Sports Dropout Rate for Girls Six Times Rate for Boys.” Momsteam,
              2006.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://gallery.mailchimp.com/3cf06618ff8dc69b8880167d0/files/fda58e14-0747-470c-bd77-1140f97a04d2/Sport_for_Healing_Edgework_2019.pdf"
                }
              >
                Edgework Consulting,
              </OutsideLink>{" "}
              “Sport for Healing: The Case for Sport as a Medium to Foster
              Healing From Psychological Trauma.” Edgework Consulting, 2019.
            </li>

            <li>
              Filip Van Droogenbroeck, Bram Spruyt and Gil Keppens,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://bmcpsychiatry.biomedcentral.com/articles/10.1186/s12888-018-1591-4"
                }
              >
                BMC Psychiatry.
              </OutsideLink>{" "}
              “Gender Differences in Mental Health Problems Among Adolescents
              and the Role of Social Support: Results From the Belgian Health
              Interview Surveys 2008 and 2013.” BioMed Central Ltd, 2018.
            </li>

            <li>
              Kim Constantinesco,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://purpose2play.com/2015/03/14/timeout-qa-men-coaching-girls-and-women/"
                }
              >
                Fansided.
              </OutsideLink>{" "}
              “Timeout Q&A: Men Coaching Girls And Women.” Fansided Inc, 2015.
            </li>

            <li>
              Amanda J. Rose and Karen D. Rudolph,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3160171/"}
              >
                PubMed Central.
              </OutsideLink>{" "}
              “A Review of Sex Differences in Peer Relationship Processes:
              Potential Trade-offs for the Emotional and Behavioral Development
              of Girls and Boys.” Psychological Bulletin, 2006.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/dp/B00OI5PGWU/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1"
                }
              >
                Kelly McGonigal,
              </OutsideLink>{" "}
              The Upside of Stress: Why Stress Is Good for You, and How to Get
              Good at It. Avery, 2015.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/dp/B00G3L1C2K/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1"
                }
              >
                Bessel van der Kolk,
              </OutsideLink>{" "}
              The Body Keeps the Score: Brain, Mind, and Body in the Healing of
              Trauma, 2014
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/dp/B00G3L1C2K/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1"
                }
              >
                Bessel van der Kolk,
              </OutsideLink>{" "}
              The Body Keeps the Score: Brain, Mind, and Body in the Healing of
              Trauma, 2014
            </li>

            <li>
              Anne Driscoll,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.brandeis.edu/investigate/selectedwork/docs/anne-driscoll-articles-giving-girls-a-sporting-chance.pdf"
                }
              >
                Brandeis University.
              </OutsideLink>{" "}
              “Giving Girls a Sporting Chance.” Boston Globe, 1999.
            </li>

            <li>
              Anne Driscoll,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.brandeis.edu/investigate/selectedwork/docs/anne-driscoll-articles-giving-girls-a-sporting-chance.pdf"
                }
              >
                Brandeis University.
              </OutsideLink>{" "}
              “Giving Girls a Sporting Chance.” Boston Globe, 1999.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/Treating-Traumatic-Stress-Children-Adolescents/dp/1606236253"
                }
              >
                Margaret E Blaustein and Kristine M. Kinniburgh
              </OutsideLink>{" "}
              , Treating Traumatic Stress in Children and Adolescents: How to
              Foster Resilience through Attachment, Self-Regulation, and
              Competency. The Guilford Press, 2010.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://www.amazon.com/dp/B000SFD21Q/"}
              >
                John J. Ratey
              </OutsideLink>{" "}
              , Spark: The Revolutionary New Science of Exercise and the Brain.
              Little, Brown Spark, 2008.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://web.archive.org/web/20190123233849/https://www.ey.com/br/pt/about-us/our-sponsorships-and-programs/women-athletes-global-leadership-network---infographic-women-sport-and-leadership"
                }
              >
                Ernst & Young
              </OutsideLink>{" "}
              , “Infographic: Women, Sport and Leadership.” Ernst & Young LLP,
              2014.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://www.amazon.com/dp/B0034DGPKY/"}
              >
                Meg Whitman
              </OutsideLink>{" "}
              , The Power of Many: Values for Success in Business and in Life.
              Crown Business, 2010.
            </li>

            <li>
              Coach Jim,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://activeforlife.com/coaching-girls-in-sport-research/"
                }
              >
                Active for Life.
              </OutsideLink>{" "}
              “Coaching Girls in Sport: What The Research Says.” Active for
              Life, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/Vital-Connections-Harnessing-Relationship-Impact-ebook/dp/B07BL5J8CV"
                }
              >
                Lou Bergholz
              </OutsideLink>{" "}
              , Vital Connections: Harnessing the Power of Relationship to
              Impact the Lives of Young People. Lioncrest Publishing, 2018.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/Vital-Connections-Harnessing-Relationship-Impact-ebook/dp/B07BL5J8CV"
                }
              >
                Lou Bergholz
              </OutsideLink>{" "}
              , Vital Connections: Harnessing the Power of Relationship to
              Impact the Lives of Young People. Lioncrest Publishing, 2018.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.amazon.com/Vital-Connections-Harnessing-Relationship-Impact-ebook/dp/B07BL5J8CV"
                }
              >
                Lou Bergholz
              </OutsideLink>{" "}
              , Vital Connections: Harnessing the Power of Relationship to
              Impact the Lives of Young People. Lioncrest Publishing, 2018.
            </li>

            <li>
              Anson Dorrance,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.teamusa.org/-/media/TeamUSA/SportPerformance/coaching/Olympic-Coach-E-Mag/Spring-2006.pdf?la=en&hash=DACDD5BB09C2AF8BF6A5578AC4B51DE964BD1756"
                }
              >
                Olympic Coach.
              </OutsideLink>{" "}
              “Coaching Women: Going Against the Instincts of My Gender.” United
              States Olympic Committee Coaching Division, 2006.
            </li>

            <li>
              Anson Dorrance,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.teamusa.org/-/media/TeamUSA/SportPerformance/coaching/Olympic-Coach-E-Mag/Spring-2006.pdf?la=en&hash=DACDD5BB09C2AF8BF6A5578AC4B51DE964BD1756"
                }
              >
                Olympic Coach.
              </OutsideLink>{" "}
              “Coaching Women: Going Against the Instincts of My Gender.” United
              States Olympic Committee Coaching Division, 2006.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://www.amazon.com/dp/B000JMKSWY/"}
              >
                Brooke de Lench
              </OutsideLink>{" "}
              , Home Team Advantage: The Critical Role of Mothers in Youth
              Sports. HarperCollins e-books, 2009.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://files.eric.ed.gov/fulltext/EJ992133.pdf"}
              >
                Ellen Markowitz
              </OutsideLink>{" "}
              , “Exploring Self-Esteem in a Girls’ Sports Program: Competencies
              and Connections Create Change.” 2012
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://rulingourexperiences.com/girls-and-sports"}
              >
                The Girls’ Index
              </OutsideLink>{" "}
              , “New Insights Into the Complex World of Today’s Girls.” Ruling
              Our Experiences, 2017.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>{" "}
              , “Sports Benefit Girls in Many Ways.” Agora Company, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>
              , “Sports Benefit Girls in Many Ways,” 2019
            </li>

            <li>
              Kavitha A. Davidson,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://www.espn.com/espnw/sports/article/22968430/study-shows-positive-correlation-playing-sports-better-self-image-girls"
                }
              >
                ESPNW.
              </OutsideLink>{" "}
              “Study Shows Positive Correlation Between Playing Sports, Better
              Self-image Among Girls.” ESPN, 2018.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>
              , “Sports Benefit Girls in Many Ways,” 2019
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>
              , “Sports Benefit Girls in Many Ways,” 2019
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>
              , “Sports Benefit Girls in Many Ways,” 2019
            </li>

            <li>
              David Cox,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.theguardian.com/lifeandstyle/2017/aug/28/young-at-heart-why-children-who-exercise-become-healthier-adults"
                }
              >
                The Guardian.
              </OutsideLink>{" "}
              “Young at Heart: Why Children Who Exercise Become Healthier
              Adults.” Guardian News & Media Limited, 2017.
            </li>

            <li>
              Allison M. Schultz,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"http://thesportjournal.org/article/you-go-girl/"}
              >
                The Sport Journal.
              </OutsideLink>{" "}
              “You Go Girl ! The Link Between Girls’ Positive Self-Esteem and
              Sports.” United States Sports Academy, 2008.
            </li>

            <li>
              David Levine,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://health.usnews.com/health-care/patient-advice/articles/2017-08-22/why-teen-girls-are-at-such-a-high-risk-for-depression"
                }
              >
                U.S. News and World Report.
              </OutsideLink>{" "}
              “Why Teen Girls Are at Such a High Risk for Depression.” U.S. News
              and World Report, 2017.
            </li>

            <li>
              Deborah Farmer Kris,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.kqed.org/mindshift/52994/how-to-help-teenage-girls-reframe-anxiety-and-strengthen-resilience"
                }
              >
                KQED.
              </OutsideLink>{" "}
              “How to Help Teenage Girls Reframe Anxiety and Strengthen
              Resilience.” KQED Inc, 2019.
            </li>

            <li>
              David Levine,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://health.usnews.com/health-care/patient-advice/articles/2017-08-22/why-teen-girls-are-at-such-a-high-risk-for-depression"
                }
              >
                U.S. News and World Report.
              </OutsideLink>{" "}
              “Why Teen Girls Are at Such a High Risk for Depression.” U.S. News
              and World Report, 2017.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://kidshealth.org/en/teens/girls-sports.html"}
              >
                TeensHealth
              </OutsideLink>{" "}
              , “5 Reasons for Girls to Play Sports.” The Nemours Foundation,
              2019.
            </li>

            <li>
              Sabina Super, Carlijn Q. Wentink, Kirsten T. Verkooijen and Maria
              A. Koelen,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.tandfonline.com/doi/full/10.1080/2159676X.2017.1361468"
                }
              >
                Qualitative Research in Sport, Exercise and Health.
              </OutsideLink>{" "}
              “How Young Adults Reflect on the Role of Sport in Their Socially
              Vulnerable Childhood.” Informa UK Limited, 2017.
            </li>

            <li>
              Allison M. Schultz,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"http://thesportjournal.org/article/you-go-girl/"}
              >
                The Sport Journal.
              </OutsideLink>{" "}
              “You Go Girl ! The Link Between Girls’ Positive Self-Esteem and
              Sports.” United States Sports Academy, 2008.
            </li>

            <li>
              Allison M. Schultz,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"http://thesportjournal.org/article/you-go-girl/"}
              >
                The Sport Journal.
              </OutsideLink>{" "}
              “You Go Girl ! The Link Between Girls’ Positive Self-Esteem and
              Sports.” United States Sports Academy, 2008.
            </li>

            <li>
              Allison M. Schultz,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"http://thesportjournal.org/article/you-go-girl/"}
              >
                The Sport Journal.
              </OutsideLink>{" "}
              “You Go Girl ! The Link Between Girls’ Positive Self-Esteem and
              Sports.” United States Sports Academy, 2008.
            </li>

            <li>
              Kavitha A. Davidson,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://www.espn.com/espnw/sports/article/22968430/study-shows-positive-correlation-playing-sports-better-self-image-girls"
                }
              >
                ESPNW.
              </OutsideLink>{" "}
              “Study Shows Positive Correlation Between Playing Sports, Better
              Self-image Among Girls.” ESPN, 2018.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>{" "}
              , “Sports Benefit Girls in Many Ways.” Agora Company, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://jumpstart-kgis.respectgroupinc.com/contents/handouts/generic/en/kgis/CoachingFemaleAthletes_BCCoaches_Aug_2011.pdf"
                }
              >
                Coaches BC
              </OutsideLink>{" "}
              , “Coaching Female Athletes.” Coaches Association of BC, 2008.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>{" "}
              , “Sports Benefit Girls in Many Ways.” Agora Company, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://childrensmedicalgroup.net/sports-benefit-girls-in-many-ways/"
                }
              >
                Children’s Medical Group
              </OutsideLink>{" "}
              , “Sports Benefit Girls in Many Ways.” Agora Company, 2019.
            </li>

            <li>
              Kavitha A. Davidson,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://www.espn.com/espnw/sports/article/22968430/study-shows-positive-correlation-playing-sports-better-self-image-girls"
                }
              >
                ESPNW.
              </OutsideLink>{" "}
              “Study Shows Positive Correlation Between Playing Sports, Better
              Self-image Among Girls.” ESPN, 2018.
            </li>

            <li>
              Alex Flanagan,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://ilovetowatchyouplay.com/2018/04/05/benefits-of-girls-playing-sports/"
                }
              >
                I Love to Watch You Play.
              </OutsideLink>{" "}
              “Why It Is Critical for Girls to Play Sports.” I Love to Watch You
              Play, 2018.
            </li>

            <li>
              Allison M. Schultz,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"http://thesportjournal.org/article/you-go-girl/"}
              >
                The Sport Journal.
              </OutsideLink>{" "}
              “You Go Girl ! The Link Between Girls’ Positive Self-Esteem and
              Sports.” United States Sports Academy, 2008.
            </li>

            <li>
              Allison M. Schultz,{" "}
              <OutsideLink
                color={"seafoam"}
                link={"http://thesportjournal.org/article/you-go-girl/"}
              >
                The Sport Journal.
              </OutsideLink>{" "}
              “You Go Girl ! The Link Between Girls’ Positive Self-Esteem and
              Sports.” United States Sports Academy, 2008.
            </li>

            <li>
              Alex Flanagan,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://ilovetowatchyouplay.com/2018/04/05/benefits-of-girls-playing-sports/"
                }
              >
                I Love to Watch You Play.
              </OutsideLink>{" "}
              “Why It Is Critical for Girls to Play Sports.” I Love to Watch You
              Play, 2018.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://www.childrenshospital.org/centers-and-services/programs/f-_-n/female-athlete-program"
                }
              >
                Boston Children’s Hospital
              </OutsideLink>{" "}
              , “Female Athlete Program” Boston Children’s Hospital, 2019.
            </li>

            <li>
              Michelle Healy,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.usatoday.com/story/news/nation/2013/08/06/injuries-athletes-kids-sports/2612429/"
                }
              >
                USA Today,
              </OutsideLink>{" "}
              “1.35 Million Youths a Year Have Serious Sports Injuries.” USA
              Today, 2013.
            </li>

            <li>
              Kieran Pender,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.telegraph.co.uk/world-cup/2019/07/13/revealed-next-frontier-sports-science-usas-secret-weapon-womens/"
                }
              >
                The Telegraph.
              </OutsideLink>
              “Ending Period 'Taboo' Gave USA Marginal Gain at World Cup.”
              Telegraph Media Group Limited, 2019.
            </li>

            <li>
              Kieran Pender,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.telegraph.co.uk/world-cup/2019/07/13/revealed-next-frontier-sports-science-usas-secret-weapon-womens/"
                }
              >
                The Telegraph.
              </OutsideLink>
              “Ending Period 'Taboo' Gave USA Marginal Gain at World Cup.”
              Telegraph Media Group Limited, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://www.childrenshospital.org/centers-and-services/programs/f-_-n/female-athlete-program"
                }
              >
                Boston Children’s Hospital
              </OutsideLink>{" "}
              , “Female Athlete Program.” Boston Children’s Hospital, 2019.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://www.childrenshospital.org/centers-and-services/programs/f-_-n/female-athlete-program"
                }
              >
                Boston Children’s Hospital
              </OutsideLink>{" "}
              , “Female Athlete Program.” Boston Children’s Hospital, 2019.
            </li>

            <li>
              Kristen Gowdy,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.womenssportsfoundation.org/health/menstrual-cycle-tracking-female-athlete/"
                }
              >
                Women's Sports Foundation.
              </OutsideLink>{" "}
              “Menstrual Cycle Tracking and the Female Athlete.” Women’s Sports
              Foundation, 2019.
            </li>

            <li>
              Kimberly S. Clay,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.modernwellnessguide.com/childhood-obesity/the-importance-of-sports-in-girls-development-2/#"
                }
              >
                Modern Wellness Guide.
              </OutsideLink>{" "}
              “The Importance of Sports in Girls’ Development.” Mediaplanet
              Group, 2019.
            </li>

            <li>
              Ellen J. Staurowsky, Mary Jane De Souza, Kathleen E. Miller, Sabo,
              D., Sohaila Shakib, Nancy Theberge, Veliz, P., Weaver, A., Nancy
              I. Williams,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://www.womenssportsfoundation.org/wp-content/uploads/2017/06/hldoi-iii-report-executive-summary.pdf"
                }
              >
                Women’s Sports Foundation.
              </OutsideLink>{" "}
              “Her Life Depends on It III: Sport, Physical Activity, and the
              Health and Well-Being of American Girls and Women.” Women’s Sports
              Foundation, 2015.
            </li>

            <li>
              David Cox,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.theguardian.com/lifeandstyle/2017/aug/28/young-at-heart-why-children-who-exercise-become-healthier-adults"
                }
              >
                The Guardian.
              </OutsideLink>{" "}
              “Young at Heart: Why Children Who Exercise Become Healthier
              Adults.” Guardian News & Media Limited, 2017.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={
                  "http://newtongirlssoccer.org/wp-content/uploads/2014/08/25-Benefits-of-Girls-Playing-Sports.pdf"
                }
              >
                Newton Girls Soccer.
              </OutsideLink>{" "}
              “25 Benefits of Girls’ Playing Sports” Newton Girls Soccer, 2014.
            </li>

            <li>
              Catherine Sabiston,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.anxiety.org/team-sports-help-teens-avoid-depression"
                }
              >
                Anxiety.org.
              </OutsideLink>{" "}
              “Why Kids in Team Sports Become Happier Adults.” Consumer Brands
              LLC, 2016.
            </li>

            <li>
              David Cox,{" "}
              <OutsideLink
                color={"seafoam"}
                link={
                  "https://www.theguardian.com/lifeandstyle/2017/aug/28/young-at-heart-why-children-who-exercise-become-healthier-adults"
                }
              >
                The Guardian.
              </OutsideLink>{" "}
              “Young at Heart: Why Children Who Exercise Become Healthier
              Adults.” Guardian News & Media Limited, 2017.
            </li>

            <li>
              <OutsideLink
                color={"seafoam"}
                link={"https://www.amazon.com/dp/B000JMKSWY/"}
              >
                Brooke de Lench
              </OutsideLink>{" "}
              “25 Benefits of Girls’ Playing Sports” Newton Girls Soccer, 2014.
            </li>
          </Ul>
        </Box>
      </InfoboxDetailed>

      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 7, 0, false)
